import {ThunkAction} from "redux-thunk"
import {RootState} from "../store"
import {ChartType, getChartData} from "../../api/chartData"
import {loadChart, popupChartSaga, updateChart} from "./actions"
import {Cohort} from "../../interface"
import {getPersonIDs, getProgressionFreeSurvivalMonthsChartData, getSurvivalChartData} from "../../data/data"

type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>

const getChart = async (type: ChartType, cohorts: Cohort[]) => {
    const data = await getChartData({chart: type, cohorts: cohorts as Cohort[]})
    let x;
    if (type === ChartType.overall_survival || type === ChartType.progression_free_survival || type === ChartType.scatter_plot) {
        x = data;
    } else {
        x = {...data}
    }
    return x
}

export const loadChartData = (type: ChartType, cohorts: Cohort[]): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        //the survival charts are broken in the RESTful API so lets do them the old way
        if (0) {//(type === ChartType.overall_survival || type === ChartType.progression_free_survival) {
            const personIds = await getPersonIDs(cohorts[0].variables);
            if (type === ChartType.overall_survival) {
                let x = await getSurvivalChartData(personIds, cohorts[0].name);
                if (x) dispatch(updateChart(type, x))

            }
            if (type === ChartType.progression_free_survival) {
                let x = await getProgressionFreeSurvivalMonthsChartData(personIds, cohorts[0].name);
                if (x) dispatch(updateChart(type, x))
            }

        } else {
            console.log("getting ", type)
            const data = await getChartData({chart: type, cohorts: cohorts as Cohort[]})
            console.log(data)
            let x;
            if (type === ChartType.overall_survival || type === ChartType.progression_free_survival || type === ChartType.scatter_plot) {
                x = data;
            } else {
                x = {...data}
            }
            if (x) dispatch(updateChart(type, x))
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const loadChartsData = (type: ChartType, cohorts: Cohort[]): ThunkResult<Promise<boolean>> => async dispatch => {
    //console.log("here",cohorts)
    try {
        if (type) {
            dispatch(loadChart(type, cohorts))
        }

        //we can premptive load other charts here, however
        // since we can not cancel the loading we should not do this

        // if (type !== ChartType.sankey) {
        //     let x = await getChart(ChartType.sankey, cohorts);
        //     if (x) dispatch(updateChart(ChartType.sankey, x))
        // }

        // if ( type !== ChartType.overall_survival) {
        //     let x = await getChart(ChartType.overall_survival, cohorts);
        //     if (x) dispatch(updateChart(ChartType.overall_survival, x))
        // }
        // if (type !== ChartType.progression_free_survival) {
        //     let x = await getChart(ChartType.progression_free_survival, cohorts);
        //     if (x) dispatch(updateChart(ChartType.progression_free_survival, x))
        // }
        // if (type !== ChartType.sunburst) {
        //     let x = await getChart(ChartType.sunburst, cohorts);
        //     if (x) dispatch(updateChart(ChartType.sunburst, x))
        //     if (x) dispatch(updateChart(ChartType.zoom_sunburst, x))
        // }

    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const loadChartsData_old = (type: ChartType, cohorts: Cohort[]): ThunkResult<Promise<boolean>> => async dispatch => {
    //console.log("here",cohorts)
    try {
        if (type) {
            // console.log(type)

            //for zoom sunburst we use the sunburst chart
            if (type === ChartType.zoom_sunburst) {
                let x = await getChart(ChartType.sunburst, cohorts);
                if (x) dispatch(updateChart(type, x))
            } else {
                let x = await getChart(type, cohorts);
                if (x) dispatch(updateChart(type, x))
            }

        }

        //we can premptive load other charts here, however
        // since we can not cancel the loading we should not do this

        // if (type !== ChartType.sankey) {
        //     let x = await getChart(ChartType.sankey, cohorts);
        //     if (x) dispatch(updateChart(ChartType.sankey, x))
        // }

        // if ( type !== ChartType.overall_survival) {
        //     let x = await getChart(ChartType.overall_survival, cohorts);
        //     if (x) dispatch(updateChart(ChartType.overall_survival, x))
        // }
        // if (type !== ChartType.progression_free_survival) {
        //     let x = await getChart(ChartType.progression_free_survival, cohorts);
        //     if (x) dispatch(updateChart(ChartType.progression_free_survival, x))
        // }
        // if (type !== ChartType.sunburst) {
        //     let x = await getChart(ChartType.sunburst, cohorts);
        //     if (x) dispatch(updateChart(ChartType.sunburst, x))
        //     if (x) dispatch(updateChart(ChartType.zoom_sunburst, x))
        // }

    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const loadPopupChartData = (line_of_therapy: number, ids: number[]): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(popupChartSaga(line_of_therapy, ids))
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}