import "react-app-polyfill/ie9";
import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpSuccess from "./pages/SignUpSuccess";
import Dashboard from "./pages/Dashboard";
import store from "./redux/store";
import SignUpVerify from "./pages/SignUpVerify";
import SignInForgotPassword from "./pages/SignInForgotPassword";
import {StyledEngineProvider} from "@mui/material";

const defaultTheme = window.EE && window.EE.theme ? window.EE.theme : {
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
};
const defaultChartColors = window.EE && window.EE.charts ? window.EE.charts : [
  "#1f77b4",
  "#2ca02c",
  "#ff7f0e",
  "#a92727",
  "#4db6ac",
  "#82488c",
  "#a84466"
];

const theme = createTheme({...defaultTheme, charts: defaultChartColors});
const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={ store }>
    <ThemeProvider theme={ theme }>
      <StyledEngineProvider injectFirst>
      <CssBaseline/>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ SignIn }/>
          <Route exact path="/signup" component={ SignUp }/>
          <Route exact path="/verify" component={ SignUpVerify }/>
          <Route exact path="/success" component={ SignUpSuccess }/>
          <Route exact path="/forgot" component={ SignInForgotPassword }/>
          <Route exact path="/dashboard" component={ Dashboard }/>
        </Switch>
      </BrowserRouter>
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>,
);
