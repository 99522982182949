import {all, call, put, takeLatest} from "redux-saga/effects";
import {ChartsAction} from './models'
import {types} from './actions'
import {ChartType, getChartData} from "../../api/chartData"
import {Cohort} from "../../interface"

const getChart = async (params: any) => {
  let type = params.type
  const cohorts = params.cohorts
  // todo: Remove next line when backend has data to return
  if(type === ChartType.geolocation) type = ChartType.progression_free_survival

  const data = await getChartData({chart: type, cohorts: cohorts as Cohort[]})
  let x;
  if (type === ChartType.overall_survival || type === ChartType.progression_free_survival || type === ChartType.scatter_plot || type === ChartType.correlation_heatmap) {
    x = data;
  } else {
    x = {...data}
  }
  return x
}

function* loadChartData(action: ChartsAction) {
  yield put({type: types.CLEAR_CHART, chart: action.type});  //signal we are changing chart
  let chart = action.chart
  if (chart === ChartType.zoom_sunburst) {
    chart = ChartType.sunburst
  }
  //const data = yield call(getChartData, { chart: action.chart as ChartType, cohorts: action.cohorts });
  const data: object = yield call(getChart, {type: chart, cohorts: action.cohorts});
  if (data) {
    yield put({chart: action.chart, type: types.UPDATE_CHART, data: data});  //signal we are changing chart
  } else {
    console.log("error big time")
  }
}

function* loadPopupChart(action: ChartsAction) {
  yield put({ type: types.POPUP_CHART_REQUEST });  //signal we are changing chart
  const line_of_therapy = action.input.line_of_therapy as number;
  const ids = action.input.ids as number[];
  const data: object = yield call(getChartData, {  chart: ChartType.sankey_node,
            line_of_therapy,
            ids });
  yield put({type: types.POPUP_CHART_SUCCESS, data: data });  //signal we are changing chart
}


function* loadPopupChartAsync() {
  yield takeLatest(types.POPUP_CHART_SAGA, loadPopupChart);
}

function* loadChartDataAsync() {
  yield takeLatest(types.LOAD_CHART_ASYNC, loadChartData);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* () {
  yield all([ loadChartDataAsync(),loadPopupChartAsync()]);
}
