import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";

const ChangedCohort = ({ open, callback, cohort }) => {
  const [loading, setLoading] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const handleSave = (save) => {
    setLoading((s) => !s);
    callback(save);
    setLoading((s) => !s);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEnter={() => {
        setNewTitle(cohort.name + " Save Changes?");
      }}
      open={open}
      onClose={callback}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Save Changes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to save changes to this cohort?
        </DialogContentText>
        {/* <TextField
          autoFocus
          margin="dense"
          id="cohortName"
          label="Cohort Name"
          type="text"
          value={newTitle}
          onChange={(e) => {
            setNewTitle(e.target.value);
          }}
          fullWidth
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSave(false)}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSave(true)}
          color="primary"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangedCohort;
