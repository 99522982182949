import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Container,
  Typography,
  Button,
  FormHelperText,
  TextField,
  Tooltip
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { signInForgotPassword, signInChange } from "../redux/Account/thunks";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  input: {
    margin: theme.spacing(0, 0, 2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  error: {
    color: "#ff0000",
  },
  success: {
    color: "#05a000"
  }
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const PasswordTooltip = (props) => {
  const classes = useStyles()
  return (
    <div>
      <p className={ !props.length ? classes.error : classes.success }>Password must be at least 8 characters long</p>
      <p className={ !props.uppercase ? classes.error : classes.success }>Password must have at least one uppercase
        letter</p>
      <p className={ !props.lowercase ? classes.error : classes.success }>Password must have at least one lowercase
        letter</p>
      <p className={ !props.number ? classes.error : classes.success }>Password must have at least one number</p>
    </div>
  )
};

const SignInForgotPassword = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [username, setUsername] = useState()
  const [newPassword, setNewPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [code, setCode] = useState()
  const [passwordTooltip, setPasswordTooltip] = useState(false)
  const [repeatPassTooltip, setRepeatPassTooltip] = useState(false)
  const [matchPasswords, setMatchPasswords] = useState(false)
  const [passwordReqs, setPasswordReqs] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false
  })
  const [changeSuccess, setChangeSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const submitted = await dispatch(signInForgotPassword(username))
    if(submitted) setNewPassword(submitted)
  };

  const handleNewPassword = async (e) => {
    e.preventDefault()
    const changed = await dispatch(signInChange(username, code, password))
    if(changed) setChangeSuccess(true)
  };

  useEffect(() => {
    password.length >= 8 ?
      setPasswordReqs(state => ({...state, length: true})) :
      setPasswordReqs(state => ({...state, length: false}))
    password.match(/(?=.*[a-z])/) ?
      setPasswordReqs(state => ({...state, lowercase: true})) :
      setPasswordReqs(state => ({...state, lowercase: false}))
    password.match(/(?=.*[A-Z])/) ?
      setPasswordReqs(state => ({...state, uppercase: true})) :
      setPasswordReqs(state => ({...state, uppercase: false}))
    password.match(/(?=.*[0-9])/) ?
      setPasswordReqs(state => ({...state, number: true})) :
      setPasswordReqs(state => ({...state, number: false}))
  }, [password])

  useEffect(() => {
    password.length > 1 && password === repeatPassword ?
      setMatchPasswords(true) : setMatchPasswords(false)
  }, [password, repeatPassword])

  const {errors} = props;

  return (
    <Container component="main" maxWidth="xs">
      <div className={ classes.paper }>
        <p>
          { process.env.REACT_APP_CLIENT_NAME ?
            <img src={ `https://engine-templates.s3.amazonaws.com/${ process.env.REACT_APP_CLIENT_NAME }/logo.svg` }
                 alt={ process.env.REACT_APP_CLIENT_NAME }
                 width={ 210 }/>
            : null }
        </p>
        <p>
          <img src="logo.svg" width={ 210 } alt="Clinical Decision Support"/>
        </p>
        { newPassword && !changeSuccess ?
          <>
            <Typography component="h1" variant="h5">
              Set new password
            </Typography>
            <Typography component="p" align="center">
              Check your email for reset code to set new password
            </Typography>
            <form className={ classes.form } noValidate onSubmit={ handleNewPassword }>
              <TextField
                className={ classes.input }
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={ username }
                disabled
              />
              <TextField
                className={ classes.input }
                variant="outlined"
                required
                fullWidth
                id="code"
                label="Reset code"
                name="code"
                autoComplete="code"
                onChange={ (e) => setCode(e.target.value) }
              />
              <LightTooltip
                title={ <PasswordTooltip { ...passwordReqs } /> }
                open={ passwordTooltip }
                arrow
                placement="right"
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <TextField
                  className={ classes.input }
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  onChange={ (e) => setPassword(e.target.value) }
                  onFocus={ () => setPasswordTooltip(true) }
                  onBlur={ () => setPasswordTooltip(false) }
                />
              </LightTooltip>
              <LightTooltip
                title={ !matchPasswords ? <span className={ classes.error }>Passwords doesn't match</span> :
                  <span className={ classes.success }>Passwords are matched</span> }
                open={ repeatPassTooltip }
                arrow
                placement="right"
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <TextField
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="repeatPassword"
                  label="Repeat Password"
                  name="repeatPassword"
                  autoComplete="repeatPassword"
                  onChange={ (e) => setRepeatPassword(e.target.value) }
                  onFocus={ () => setRepeatPassTooltip(true) }
                  onBlur={ () => setRepeatPassTooltip(false) }
                />
              </LightTooltip>
              { errors ? errors.map((error, key) =>
                <FormHelperText key={ key } className={ classes.error }>{ error.msg }</FormHelperText>
              ) : null }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={ classes.submit }
                disabled={ !matchPasswords }
              >
                Set New Password
              </Button>
            </form>
          </> : null }
        { !newPassword ?
          <>
            <Typography component="h1" variant="h5" align="center">
              Forgot your password
            </Typography>
            <Typography component="p" align="center">
              Enter your email address and we will send you an email with password reset code
            </Typography>
            <form className={ classes.form } noValidate onSubmit={ handleSubmit }>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email address"
                name="email"
                autoComplete="email"
                onChange={ (e) => setUsername(e.target.value) }
              />
              { errors ? errors.map((error, key) =>
                <FormHelperText key={ key } className={ classes.error }>{ error.msg }</FormHelperText>
              ) : null }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={ classes.submit }
              >
                Send Password Reset Email
              </Button>
            </form>
          </> : null }
        { newPassword && changeSuccess ?
          <>
            <Typography component="h1" variant="h5" align="center">
              Password change successful
            </Typography>
            <Typography component="p" align="center">
              You have successfully changed the password<br/>
              Continue to <Link to="/">Sign in page</Link> to access Clinical Decision Support
            </Typography>
          </> : null }
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  ...state.signIn
})

const mapDispatch = {
  signInForgotPassword,
  signInChange
}

export default connect(mapStateToProps, mapDispatch)(SignInForgotPassword)