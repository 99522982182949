import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  nameChange: {
    zIndex: 900000,
  },
}));
const ChangeName = ({ open, callback, name }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const handleSave = (save) => {
    setLoading((s) => !s);
    callback(newTitle);
    setLoading((s) => !s);
  };

  useEffect(() => {
    setNewTitle(name);
  }, [name]);

  //console.log(name, newTitle);
  return (
    <Dialog
      className={classes.nameChange}
      disableBackdropClick
      disableEscapeKeyDown
      onEnter={() => {
        setNewTitle(name);
      }}
      open={open}
      onClose={callback}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Name Cohort</DialogTitle>
      <DialogContent>
        <DialogContentText>Name this Cohort</DialogContentText>
        {
          <TextField
            autoFocus
            margin="dense"
            id="cohortName"
            label="Cohort Name"
            type="text"
            value={newTitle}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
            fullWidth
          />
        }
      </DialogContent>
      <DialogActions>
        {/* <Button
          onClick={() => handleSave(false)}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button> */}
        <Button
          onClick={() => handleSave(true)}
          color="primary"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeName;
