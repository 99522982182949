import React from "react";
import { BarChart, CartesianGrid, Tooltip, Bar, XAxis, YAxis } from "recharts";
import { Close } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

/*
    value is an VariableData object (interface/index.ts)
    color = color to draw chart
    filter =[0, 10] or ['a', 'b', 'c'] // this is the current active filter setting on data
    onChange  //this is callback with is called with filter on filter change. 
    onRemove  //this is called when this chart is removed
    
*/
function DiscreteCohortChart({ value, color, filter, onChange, onRemove }) {
  if (!filter) {
    filter = value.domain;
  }

  const [myFilter, setFilter] = React.useState(null);
  const [data, setData] = React.useState(null);

  function remove() {
    if (onRemove) {
      onRemove();
    }
  }

  React.useEffect(() => {
    if (!filter || !value || !Array.isArray(filter)) {
      return;
    }

    //console.log(hist, value);
    let x = value.hist.filter((d) => filter.includes(d.bin));
    //console.log(x);
    setData(x);
    setFilter(filter);
  }, [filter, value]);

  function checkedChanged(name, state) {
    let f;
    f = myFilter;
    if (!f) {
      f = value.domain;
    }

    if (state) {
      f = [...f, name];
    } else {
      f = [...f];
      const index = f.indexOf(name);
      if (index > -1) {
        f.splice(index, 1);
      }
    }

    setFilter(f);
    if (onChange) {
      onChange(f);
    }

    let x = value.hist.filter((d) => f.includes(d.bin));
    setData(x);
  }

  //console.log(value, data);
  if (!data || data === null) {
    return <div></div>;
  }
  return (
    <div>
      <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Grid item>
          <Typography variant="h6" sx={{fontSize: 16}}>{value.name}</Typography>
        </Grid>
        <Grid item>
          <IconButton size="small" aria-label="delete" onClick={remove}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <ResponsiveContainer width="100%" height={150} key={data.length}>
        <BarChart
          key={value.name}
          data={data}
          margin={{
            top: 10,
            right: 10,
            bottom: 20,
            left: 30,
          }}
        >
          <Tooltip />
          <XAxis
            key={"x_"}
            dataKey="bin"
            angle={-15}
            textAnchor="end"
            interval={0}
            scale={"auto"}
            label={
              value.name
                ? {
                    value: value.name,
                    offset: 0,
                    position: "insideBottom",
                    dy: 35,
                  }
                : null
            }
          />
          <YAxis
            key={"y_"}
            width={25}
            label={{
              value: "count",
              angle: -90,
              position: "insideLeft",
              dy: 10,
              dx: -28,
            }}
          />
          <Bar key={"bar_"} dataKey="count" fill={color} />

          <CartesianGrid strokeDasharray="3 3" />
        </BarChart>
      </ResponsiveContainer>
      <div
        key={"div2" + value.id}
        style={{
          marginLeft: value.domain.length > 0 ? 10 : 0,
        }}
      >
        {value.domain.length > 0
          ? value.domain.map((d) => (
              <div key={"div3" + d}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        Array.isArray(myFilter) ? myFilter.includes(d) : true
                      }
                      onChange={(e) => {
                        checkedChanged(d, e.target.checked);
                      }}
                      name={d}
                      size="small"
                      style={{ padding: ".2rem" }}
                    />
                  }
                  label={d}
                  key={d}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export default DiscreteCohortChart;
