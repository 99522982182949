import React, {ReactNode, useRef, useState} from 'react';
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {Paper, Popper} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles({
    help: {
        position: 'absolute',
        top: (props: HelpProps) => props.top ? props.top : -8,
        right: (props: HelpProps) => props.right ? props.right : -6,
        borderRadius: 24,
        width: 24,
        height: 24,
        backgroundColor: 'white',
        color: 'gray',
        boxShadow: '0 1px 3px rgba(0,0,0,.4)',
        cursor: 'pointer',
        transform: 'scale(0.8)',
        '&:hover': {
            transform: 'scale(1.1)'
        },
        '& > svg': {
            pointerEvent: 'none'
        },
    },
    open: {
        transform: 'scale(1)'
    },
    popper: {
        zIndex: 10000
    },
    paper: {
        maxWidth: 400,
        maxHeight: 400,
        padding: 20,
        overflow: 'auto',
        "&::-webkit-scrollbar": {
            width: "6px",
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "rgba(0,0,0,.4)",
            borderRadius: "10px",
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            marginTop: 0
        },
        '& ol, & ul': {
            paddingLeft: 20
        }
    },
});

type HelpProps = {
    top?: number,
    right?: number,
    left?: number,
    bottom?: number,
    content: ReactNode
}

const Help = (props: HelpProps) => {
    const classes = useStyles(props);
    const reference = useRef(null);
    const [open, setOpen] = useState(false);

    return (
        <div onClick={() => setOpen(s => !s)}>
            <div className={clsx({[classes.help]:true, [classes.open]:open})}
                 ref={reference}>
                { open ? <CancelIcon/> : <HelpIcon /> }
            </div>
            <Popper open={open}
                    className={classes.popper}
                    anchorEl={reference.current}
                    placement="bottom-end">
                <Paper elevation={3}  className={classes.paper}>
                    {props.content}
                </Paper>
            </Popper>
        </div>
    )
}

export default Help;