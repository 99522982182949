import React, { useState } from "react"
import { makeStyles } from "@mui/styles";
import {
  Container,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  FormHelperText
} from "@mui/material"
import { Link } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { signIn } from "../redux/Account/thunks"
import { useHistory } from "react-router"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "#ff0000",
  },
}));

const SignIn = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const redirect = await dispatch(signIn({username, password}))
    if(redirect) history.push("/dashboard")
  }

  const {loading, errors} = props;

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <p>
          { process.env.REACT_APP_CLIENT_NAME ?
            <img src={ `https://engine-templates.s3.amazonaws.com/${ process.env.REACT_APP_CLIENT_NAME }/logo.svg` }
                 alt={ process.env.REACT_APP_CLIENT_NAME }
                 width={ 210 }/>
            : null }
        </p>
        <p>
          <img src="logo.svg" width={210} alt="Clinical Decision Support" />
        </p>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors ? errors.map((error, key) =>
            <FormHelperText key={key} className={classes.error}>{error.msg}</FormHelperText>
          ) : null}
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
            </Grid>
            <Grid item>
              <Link to="/forgot"
                    className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-body2 MuiTypography-colorPrimary"
              >
                Forgot Password?
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmit}
            disabled={loading}
          >
            Sign In
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                to="/signup"
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-body2 MuiTypography-colorPrimary"
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  ...state.signIn
})

const mapDispatch = {
  signIn
}

export default connect(mapStateToProps, mapDispatch)(SignIn)