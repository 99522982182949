import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  user: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  version: {
    fontSize: 10,
    marginLeft: "1rem",
    paddingLeft: "1rem",
    opacity: 0.5
  }
}));

const NavBar = ({ title = "Clinical Decision Support" }) => {
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useState();

  useEffect(() => {
    const session = window.localStorage.getItem("session");
    if (session && session !== void 0) {
      const sessionParsed = JSON.parse(session);
      setUser(`${sessionParsed.lastName}, ${sessionParsed.firstName}`);
    }
  }, []);

  const handleSignOut = (e) => {
    e.preventDefault();
    window.localStorage.removeItem("session");
    window.localStorage.removeItem("token");
    history.push("/");
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title}
          <Typography
            component="span"
            className={classes.version}
          >
            v0.3.0
          </Typography>
        </Typography>
        <Typography component="p" color="inherit" className={classes.user}>
          {user}
        </Typography>
        <IconButton onClick={handleSignOut} color="inherit">
          <PowerSettingsNew />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;