import React, { useState,useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Divider,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";


import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import ZoomSunburst from "./ZoomSunburst";
import { connect } from "react-redux";
import { loadPopupChartData } from "../../redux/Charts/thunks";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  item: {
    padding: theme.spacing(1),
    //display: "flexbox",
    //width: "50%",
    //height: "75%",
    //flexShrink: 1,

    // alignItems: "center",
  },
  close: {
    paddingRight: theme.spacing(1),
  },
  loadingBox: {
    minWidth: 200,
    minHeight: 150,
  },
  popper: {
    zIndex: 9999,
    width: "calc(100vw - 90px)",
    height: "calc(100vh - 90px)",
  },
  nameChange: {
    zIndex: 10000,
  },
  backdrop: {
    zIndex: 9998,
  },
  sankey: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flexbox",
    // width: "50%",
    height: "100%",

    alignItems: "center",
  },
  paper: {
    maxHeight: "90vh",
    overflow: "hidden",
  },
  tooltip: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: "0px 1px 8px 0px rgba(0,0,0,0.12)",
    "& > p": {
      margin: 0,
      textAlign: "center",
    },
  },
  barArea: {
    margin: "0 auto",
  },
}));

const SplitSunburst = (props) => {
  const { popupCharts, data, loadPopupChartData } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [node, setNode] = useState({ name: null });

  const handleNodeChange = (node) => {
    console.log("node change: ", node);
    loadPopupChartData(node.depth, node.data.ids);
    setNode((s) => {
      return { name: node.data.name, ...node };
    });
  };

  // console.log(data);
  const formatSurvivalResponse = () => {
    return popupCharts.popupData.survivalResponse
      ? popupCharts.popupData.survivalResponse
          .map((i) => {
            // console.log(node.value, i);
            switch (i.response) {
              case "Stringent Complete Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "SCR",
                  order: 1,
                };
              case "Complete Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "CR",
                  order: 2,
                };
              case "Very Good Partial Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "VGPR",
                  order: 3,
                };
              case "Partial Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "PR",
                  order: 4,
                };
              case "Stable Disease":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "SD",
                  order: 5,
                };
              case "Progressive Disease":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "PD",
                  order: 6,
                };
              case "Unknown":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "UNK",
                  order: 7,
                };
              default:
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                };
            }
          })
          .sort((a, b) => a.order - b.order)
      : null;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      //console.log(payload);
      return (
        <div className={classes.tooltip}>
          <p className="label">{`${payload[0].payload.response}`}</p>
          <p className="intro">{`${payload[0].value}`}</p>
          <p className="intro">{`${payload[0].payload.percent.toFixed(2)}%`}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    //console.log(data)
    //console.log(popupCharts)
    //if not loading and data for popupcharts does not exists
    if (!popupCharts.request && (!popupCharts.popupData || !popupCharts.popupData.survivalResponse)) 
    {
      //if we have Sunburst data
      if (data && data.children)
      {
        //console.log("load new popup")
        //find the child with most children
        var i=0;
        var cnt=0;
        data.children.forEach((child,index) => {
          if (child.ids.length>cnt){
            cnt=child.ids.length
            i=index;
          }
        });

        const node ={data:data.children[i], depth: 1}
        handleNodeChange(node)

      }
    }
  },[data, popupCharts,handleNodeChange]);


  return (
    <>
      <Typography align="center" variant="h6">
        {data
          ? "Baseline Treatment Regimens For Each Line of Therapy (" +
            data.ids.length +
            ")"
          : "Baseline Treatment Regimens For Each Line of Therapy"}
      </Typography>

      <Divider orientation="horizontal" flexItem />
      <Grid
        container
        alignItems="center"
        className={classes.container}
        sx={{ display: "flex", flexFlow: "row" }}
      >
        <Grid item className={classes.item} style={{ width: "380px", minWidth: "380px" }}>
          <Typography align="center" variant="h6" gutterBottom>
            {`Line of Therapy Outcome Response`}
          </Typography>
          <Typography align="center" variant="h6" gutterBottom>
            {`for ${node.name}`}
          </Typography>
          <ResponsiveContainer width={350} minHeight={250}>
            {popupCharts.loading ? (
              <Grid item className={classes.item}>
                <Box
                  className={classes.loadingBox}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              </Grid>
            ) : (
              <BarChart
                data={formatSurvivalResponse()}
                margin={{
                  left: -10,
                  bottom: 20,
                }}
              >
                <Tooltip content={<CustomTooltip />} />
                <XAxis
                  dataKey="label"
                  label={{
                    value: "Outcome",
                    angle: 0,
                    position: "insideBottom",
                    dy: 10,
                    dx: 0,
                  }}
                />
                <YAxis
                  dataKey="count"
                  label={{
                    value: "Patients",
                    angle: -90,
                    position: "insideLeft",
                    dy: 20,
                    dx: 14,
                  }}
                />

                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="count" fill={theme.charts[0]} />
              </BarChart>
            )}
          </ResponsiveContainer>
        </Grid>

        <Divider orientation="vertical" flexItem style={{ width: "1px" }} />

        <Grid
          item
          className={classes.sankey}
          style={{ dislay: "flex", flexFlow: "row" }}
        >
          <ZoomSunburst data={data} nodeSelectedCallback={handleNodeChange} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.charts.popupCharts,
});

const mapDispatch = {
  loadPopupChartData,
};

export default connect(mapStateToProps, mapDispatch)(SplitSunburst);
