import React, {useRef, useState} from "react";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
  Divider,
  Box,
  CircularProgress, ListItemButton, Modal, Typography, Input, ListItem,
} from "@mui/material";
import {
  AddBox,
  LocalPharmacy,
  GroupWork,
  People,
  ExpandLess,
  ExpandMore,
  CallMerge
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDna } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { addVariable } from "../redux/Cohorts/actions";
import { grey } from "@mui/material/colors";
import {useHotkeys} from "react-hotkeys-hook";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  subheaders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    color: grey[900],
    cursor: "pointer",
    userSelect: "none",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(2),
    transition: "none",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      '& $addButton': {
        display: 'block'
      }
    }
  },
  nestedText: {
    fontSize: 13,
  },
  label: {
    width: "100%",
    marginRight: 0,
  },
  loadingBox: {
    height: "100%",
  },
  icon: {
    minWidth: "36px"
  },
  itemText: {
    fontSize: 14
  },
  addButton: {
    display: 'none',
    height: "24px",
    backgroundColor: theme.palette.primary.main
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'white',
    boxShadow: theme.boxShadow,
    padding: theme.spacing(4),
  }
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: 'white',
  boxShadow: 18
}

const VariablesList = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState('demographics');
  const [titleOpen, setTitleOpen] = useState('clinical');
  const [modalOpen, setModalOpen] = useState(false);
  useHotkeys('v', () => {
    setModalOpen(true)
  })

  const { loading, variables, addVariable } = props;

  if (loading)
    return (
      <Box
        className={classes.loadingBox}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <List>
      <Modal
        open={modalOpen}
        onClose={() => {setModalOpen(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Input placeholder="Search for variable" sx={{ padding: 2 }} fullWidth />
          <List sx={{
            maxHeight: "200px",
            overflowX: "auto",
          }}>
            {variables.map((t) => (
              t.children.map((g) => (
                g.children.map((v, key) => (
                  <ListItemButton key={key} sx={{ display: "flex", flexFlow: "column", alignItems: "start"}}>
                    <Typography sx={{fontSize: 12}}>{t.name} &gt; {g.name}</Typography>
                    <Typography sx={{fontWeight: "bold"}}>{v.name}</Typography>
                  </ListItemButton>
                ))
              ))
            ))}
          </List>
        </Box>
      </Modal>
      {variables.map((title) => (
        <div key={`title-${title.id}`}>
          <ListSubheader component="div"
                         classes={{ root: classes.subheaders }}
                         onClick={() => {
                           if(title.id === titleOpen) {
                             setTitleOpen("")
                           } else {
                             setTitleOpen(title.id)
                             setOpen(title.children[0].id)
                           }
                         }}
          >
            {title.name}
          {title.id === titleOpen ? <ExpandLess /> : <ExpandMore />}
          </ListSubheader>
          <Divider />
          <Collapse in={title.id === titleOpen} timeout="auto" unmountOnExit>
          {title.children.map((group) => (
            <div key={`group-${group.id}`}>
              <ListItemButton
                onClick={() => {
                  group.id === open ? setOpen("") : setOpen(group.id);
                }}
              >
                <ListItemIcon className={classes.icon}>
                  {group.type === "patient" ? <People /> : null}
                  {group.type === "lab" ? <GroupWork /> : null}
                  {group.type === "genomic" ? (
                    <FontAwesomeIcon icon={faDna} />
                  ) : null}
                  {group.type === "manufacturing" ? <LocalPharmacy /> : null}
                  {group.type === "derived" ? <CallMerge /> : null}
                </ListItemIcon>
                <ListItemText primary={group.name} classes={{ primary: classes.itemText }} />
                {group.id === open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={group.id === open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {group.children.map((variable) => (
                    <ListItemButton
                      key={variable.var_key}
                      classes={{ root: classes.nested }}
                      onClick={() =>
                        addVariable(variable.var_key, variable.name)
                      }
                    >
                      <ListItemText
                        primary={variable.name}
                        classes={{ primary: classes.nestedText }}
                      />
                      <div className={classes.addButton}>
                        <AddBox/>
                      </div>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
          </Collapse>
        </div>
      ))}
    </List>
  );
};

const mapStateToProps = (state) => ({
  ...state.variables,
});

const mapDispatch = {
  addVariable,
};

export default connect(mapStateToProps, mapDispatch)(VariablesList);
