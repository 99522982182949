import {ChartsAction, ChartsState} from "./models"
import {types} from './actions'
import {ChartType} from "../../api/chartData"


const initState: ChartsState = {
    chart: ChartType.sankey,

    sunburstData: {
        loading: true,
        data: []
    },
    zoomSunburstData: {
        loading: true,
        data: []
    },
    sankeyData: {
        loading: true,
        data: []
    },
    sankeyNodeData: {
        loading: true,
        data: []
    },
    survivalData: {
        loading: true,
        data: []
    },
    progressionFreeSurvivalData: {
        loading: true,
        data: []
    },
    scatterPlotData: {
        loading: true,
        data: []
    },
    correlationHeatmapData: {
        loading: true,
        data: []
    },
    geomapData: {
        loading: false,
        data: []
    },
    popupCharts: {
        loading: true,
        request: false,
        popupData: []
    }
}

export const chartsReducer = (state = initState, action: ChartsAction): ChartsState => {

    switch (action.type) {
        case types.CHANGE_CHART:
            let s = { ...state };
            return {
                ...s,
                chart: action.chart as ChartType,
            }
        
        
        case types.UPDATE_CHART:
            const x = {
                    loading: false,
                    data: action.data
                }
            if (action.chart === ChartType.sankey)
            {
                return {
                    ...state,
                    sankeyData: x
                }
            }
            if (action.chart === ChartType.sunburst)
            {
                return {
                    ...state,
                    sunburstData: x
                }
            }
            if (action.chart === ChartType.zoom_sunburst)
            {
                return {
                    ...state,
                    zoomSunburstData: x
                }
            }
            if (action.chart === ChartType.sankey_node)
            {
                return {
                    ...state,
                    sankeyNodeData: x
                }
            }
            if (action.chart === ChartType.overall_survival)
            {
                return {
                    ...state,
                    survivalData: x
                }
            }
            if (action.chart === ChartType.progression_free_survival)
            {
                return {
                    ...state,
                    progressionFreeSurvivalData: x
                }
            }
            if (action.chart === ChartType.scatter_plot)
            {
                return {
                    ...state,
                    scatterPlotData: x
                }
            }
            if (action.chart === ChartType.correlation_heatmap)
            {
                return {
                    ...state,
                    correlationHeatmapData: x
                }
            }
            if (action.chart === ChartType.geolocation)
            {
                return {
                    ...state,
                    geomapData: x
                }
            }
            return {...state}
            
        case types.CLEAR_CHART:           
            return {
                ...state,
                popupCharts: {
                    loading: true,
                    request: false,
                },
                 sunburstData: {
                    loading: true,
                },
                zoomSunburstData: {
                    loading: true,
                },
                sankeyData: {
                    loading: true,
                },
                sankeyNodeData: {
                    loading: true,
                },
                survivalData: {
                    loading: true,
                },
                progressionFreeSurvivalData: {
                    loading: true,
                },
                scatterPlotData: {
                    loading: true,
                },
                geomapData: {
                    loading: true
                }
            }

        case types.POPUP_CHART_REQUEST:
            return {
                ...state,
                popupCharts: {
                    ...state.popupCharts,
                    request:true,
                    loading: true
                }
            }
        case types.POPUP_CHART_SUCCESS:
            return {
                ...state,
                popupCharts: {
                    ...state.popupCharts,
                    loading: false,
                    request:true,
                    popupData: action.data
                }
            }



        default:
            return state
    }
}