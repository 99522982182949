import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";

const SaveCohort = ({ open, toggle, cohort }) => {
  const [loading, setLoading] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const handleSave = (save) => {
    setLoading((s) => !s);
    if (save) {
      const userString = window.localStorage.getItem("session");
      const user =
        userString && userString.length ? JSON.parse(userString) : null;
      toggle(user.id, newTitle);
    } else {
      toggle(false);
    }
    setLoading((s) => !s);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEnter={() => {
        setNewTitle(cohort.name);
      }}
      open={open}
      onClose={toggle}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Save Cohort</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to save changes to this cohort?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="cohortName"
          label="Cohort Name"
          type="text"
          value={newTitle}
          onChange={(e) => {
            setNewTitle(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSave(false)}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSave(true)}
          color="primary"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveCohort;
