import React, {useEffect, useRef} from "react";
import us from "../../data/counties-albers-10m.json";
import population from "../../data/population.json";
import * as d3 from "d3";
import {mesh, feature} from "topojson-client";

const statemap = new Map(feature(us, us.objects.states).features.map(d => [d.id, d]))
const countymap = new Map(feature(us, us.objects.counties).features.map(d => [d.id, d]))

export default function GeoMap({data}) {
  const ref = useRef();

  useEffect(() => {

    const path = d3.geoPath()
    const centroid = (feature) => path.centroid(feature)

    const draw = async () => {
      const svg = d3.select(ref.current);

      svg.attr("width", '100%')
        .attr("height", '100%')
        .attr("viewBox", "0, 0, 975, 610")
        .attr("style", "width: 100%; height: auto; height: intrinsic;");

      const counties = path(mesh(us, us.objects.counties, (a, b) => a !== b && (a.id / 1000 | 0) === (b.id / 1000 | 0)))
      svg.append("path")
        .style('fill', 'none')
        .style('stroke', '#a3a3a3')
        .style('stroke-width', '0.5')
        .attr('d', counties)

      const states = path(mesh(us, us.objects.states, (a, b) => a !== b))
      svg.append("path")
        .style('fill', 'none')
        .style('stroke', '#737373')
        .style('stroke-width', '1')
        .attr('d', states)

      const nation = path(feature(us, us.objects.nation))
      svg.append("path")
        .style('fill', 'none')
        .style('stroke', '#000')
        .style('stroke-width', '1.2')
        .attr('d', nation)

      const popData = population.map((d) => ({
        ...d,
        county: countymap.get(d.fips),
        state: statemap.get(d.state)
      }))
        .filter(d => d.county)
        .filter((d, i) => {
          // temporary filter, remove when connected to real data
          if(data[0].length < 500) {
            if(i % 2 === 0 && i % 3 === 0) return d
          } else if(data[0].length > 500 && data[0].length < 800) {
            if(i % 2 === 0) return d
          } else {
            return d
          }
        })
        .sort((a, b) => d3.descending(a.population, b.population));

      const radius = d3.scaleSqrt([0, d3.max(popData, d => d.population)], [0, 40]);
      const color = d3.scaleLinear([0, d3.max(popData, d => d.population)], ['#ff6060', '#700000'])
      const format = d3.format(",.0f");
      svg.append("g")
        .selectAll()
        .data(popData)
        .join("circle")
        .attr("transform", d => `translate(${centroid(d.county)})`)
        .attr("fill", d => color(d.population))
        .attr("fill-opacity", 0.5)
        .attr("r", d => radius(d.population))
        .append("title")
        .text(d => `${d.county.properties.name}, ${d.state.properties.name} ${format(d.population)}`);

      const spike = (length, width = 7) => `M${-width / 2},0L0,${-length}L${width / 2},0`
      const length = d3.scaleLinear([0, d3.max(popData, d => d.population)], [0, 200]);
      svg.append("g")
        .attr("fill", "blue")
        .attr("fill-opacity", 0.5)
        .selectAll()
        .data(popData)
        .join("path")
        .attr("transform", d => `translate(${centroid(d.county)})`)
        .attr("d", d => spike(length(d.population)))
        .append("title")
        .text(d => `${d.county.properties.name}, ${d.state.properties.name} ${format(d.population)}`);
    }

    if(data && data.length) {
      draw()
    }

  }, [data]);

  return (
    <svg ref={ref}/>
  )
}
