import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useTheme } from "@mui/material";

function SunburstChart({ data }) {
  const ref = useRef();
  const refBread = useRef();
  const theme = useTheme();

  const width = 640;
  const breadcrumbHeight = 30;
  const breadcrumbWidth = 75;
  const radius = 320;

  const partition = (data) =>
    d3.partition().size([2 * Math.PI, radius * radius])(
      d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)
    );

  const color = d3
    .scaleOrdinal()
    .domain(["home", "product", "search", "account", "other", "end"])
    .range(theme.charts);

  // const csv = data;
  // const formattedData = buildHierarchy(csv);
  const root = partition(data);

  function breadcrumbPoints(d, i) {
    const tipWidth = 10;
    const points = [];
    points.push("0,0");
    points.push(`${breadcrumbWidth},0`);
    points.push(`${breadcrumbWidth + tipWidth},${breadcrumbHeight / 2}`);
    points.push(`${breadcrumbWidth},${breadcrumbHeight}`);
    points.push(`0,${breadcrumbHeight}`);
    if (i > 0) {
      // Leftmost breadcrumb; don't include 6th vertex.
      points.push(`${tipWidth},${breadcrumbHeight / 2}`);
    }
    //console.log(points.join(" "));
    return points.join(" ");
  }

  useEffect(() => {
    const drawBreadCrumb = (sequence, percentage) => {
      const svg = d3.select(refBread.current);

      svg.selectAll("text").remove();
      svg
        .attr("viewBox", `0 0 ${breadcrumbWidth * 10} ${breadcrumbHeight}`)
        .style("height", "4%")
        .style("font", "12px sans-serif")
        .style("margin", "5px");

      if (!sequence) {
        svg.selectAll("text").remove();
        svg.selectAll("polygon").remove();
        return;
      }

      const g = svg
        .selectAll("g")
        .data(sequence)
        .join("g")
        .attr("transform", (d, i) => `translate(${i * breadcrumbWidth}, 0)`);

      g.append("polygon")
        .attr("points", breadcrumbPoints)
        .attr("fill", (d) => color(d.data.name))
        .attr("stroke", "white");

      g.append("text")
        .attr("x", (breadcrumbWidth + 10) / 2)
        .attr("y", 15)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .attr("fill", "white")
        .text((d) => d.data.name);

      svg
        .append("text")
        .text(percentage > 0 ? percentage + "%" : "")
        .attr("x", (sequence.length + 0.5) * breadcrumbWidth)
        .attr("y", breadcrumbHeight / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle");
    };

    const draw = () => {
      const mousearc = d3
        .arc()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .innerRadius((d) => Math.sqrt(d.y0))
        .outerRadius(radius);

      const arc = d3
        .arc()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .padAngle(1 / radius)
        .padRadius(radius)
        .innerRadius((d) => Math.sqrt(d.y0))
        .outerRadius((d) => Math.sqrt(d.y1) - 1);

      const svg = d3.select(ref.current);
      svg.selectAll("*").remove();
      // Make this into a view, so that the currently hovered sequence is available to the breadcrumb
      const element = svg.node();
      element.value = { sequence: [], percentage: 0.0 };

      const label = svg
        .append("text")
        .attr("text-anchor", "middle")
        .attr("fill", "#888")
        .style("visibility", "hidden");

      label
        .append("tspan")
        .attr("class", "percentage")
        .attr("x", 0)
        .attr("y", 0)
        .attr("dy", "-0.1em")
        .attr("font-size", "3em")
        .text("");

      label
        .append("tspan")
        .attr("x", 0)
        .attr("y", 0)
        .attr("dy", "1.5em")
        .text("of visits begin with this sequence");

      svg
        .attr("viewBox", `${-radius} ${-radius} ${width} ${(9 / 16) * width}`)
        .attr("width", "100%")
        .attr("height", "96%")
        .style("font", "12px sans-serif");

      const path = svg
        .append("g")
        .selectAll("path")
        .data(
          root.descendants().filter((d) => {
            // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
            return d.depth && d.x1 - d.x0 > 0.001;
          })
        )
        .join("path")
        .attr("fill", (d) => color(d.data.name))
        .attr("d", arc);

      svg
        .append("g")
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("mouseleave", () => {
          path.attr("fill-opacity", 1);
          label.style("visibility", "hidden");
          // Update the value of this view
          element.value = { sequence: [], percentage: 0.0 };
          element.dispatchEvent(new CustomEvent("input"));
          drawBreadCrumb(undefined, undefined);
        })
        .selectAll("path")
        .data(
          root.descendants().filter((d) => {
            // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
            return d.depth && d.x1 - d.x0 > 0.001;
          })
        )
        .join("path")
        .attr("d", mousearc)
        .on("mouseenter", (d) => {
          // Get the ancestors of the current segment, minus the root
          const sequence = d.ancestors().reverse().slice(1);
          // Highlight the ancestors
          path.attr("fill-opacity", (node) =>
            sequence.indexOf(node) >= 0 ? 1.0 : 0.3
          );
          const percentage = ((100 * d.value) / root.value).toPrecision(3);
          label
            .style("visibility", null)
            .select(".percentage")
            .text(percentage + "%");
          // Update the value of this view with the currently hovered sequence and percentage
          element.value = { sequence, percentage };
          element.dispatchEvent(new CustomEvent("input"));
          drawBreadCrumb(sequence, percentage);
        });
    };

    draw();
    drawBreadCrumb(undefined, undefined);
  }, [data, color, root]);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      <svg className="sunburstBreadcrumb" ref={refBread} />
      <svg className="sunburst" ref={ref} />
    </div>
  );
}

export default SunburstChart;
