import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const DeleteCohort = ({ open, toggle, cohort }) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = () => {
    setLoading((s) => !s);
    toggle(true, cohort);
    setLoading((s) => !s);
  };

  if (!cohort) {
    return "";
  }
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={() => {
        toggle(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete Cohort</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {cohort.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            toggle(false);
          }}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          color="secondary"
          variant="contained"
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCohort;
