import React, { useState } from "react";
import clsx from "clsx";
import {makeStyles, useTheme} from "@mui/styles";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  FormControl,
  Select,
  Input,
  MenuItem,
  Checkbox,
  Typography, InputLabel,
} from "@mui/material";
import { ChartType } from "../../api/chartData";
import { getVariableList } from "../../redux/Variables/thunks";
import { changeChart, clearChartData } from "../../redux/Charts/actions";
import { getCohortsList, saveCohortChanges } from "../../redux/Cohorts/thunks";
import {
  changeFilter,
  cohortAdd,
  removeVariable,
  toggleSelected,
} from "../../redux/Cohorts/actions";
import { connect } from "react-redux";
import SunburstChart from "../SunburstChart";
import SplitSunburst from "./SplitSunburst";
import SurvivalChart from "../SurvivalChart";
import SankeyChart from "../SankeyChart";
import PopupChart from "./PopupChart";
import Help from "../Help/Help";
import SampleHelp from "../Help/SampleHelp";
import {
  SankeyHelp,
  OverallSurvivalHelp,
  ProgressionFreeSurvivalHelp,
  ZoomSunburstHelp,
} from "../Help/HelpStrings";
import { ChartLayoutHelp } from "../Help/HelpStrings";
import ScatterPlotMatrix from "../ScatterPlotMatrix";
import CorrelationHeatmap from "../CorrelationHeatmap";
import GeoMap from "./GeoMap";

function getMultipleCheck(value, data) {
  const f = data.filter(item => item.name === value.name)
  return f && f.length && f[0].selected
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  paperFullHeight: {
    height: "100%",
    minHeight: "100%",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,.4)",
      borderRadius: "10px",
    },
  },
  charts: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  fullHeight: {
    flexFlow: "column",
    alignItems: "stretch",
    height: "100%",
  },
  outputFullHeight: {
    height: "calc(100vh - 168px)"
  },
  heightAuto: {
    flex: "0 1 auto",
    marginBottom: theme.spacing(1)
  },
  outputButton: {
    marginRight: theme.spacing(2),
    minWidth: "48px",
  },
  formControl: {
    minWidth: 300,
    maxWidth: 300,
  },
}));

const ChartsLayout = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  const [popupCharts2, setPopupCharts] = useState({ open: false, name: null });
  const [chartPick, setChartPick] = useState('sankey_node')

  const {
    chart,
    changeChart,
    cohorts,
    toggleSelected,
    sunburstData,
    zoomSunburstData,
    sankeyData,
    popupCharts,
    survivalData,
    progressionFreeSurvivalData,
    scatterPlotData,
    correlationHeatmapData,
    geomapData
  } = props;

  const handleChangeMultiple = (event, index) => {
    //console.log(index);
    toggleSelected(index.props.value);
  };

  const handlePopupCharts = (node) => {
    setPopupCharts((s) => {
      if (s.open && s.name === node.name) {
        return { open: false, name: null };
      } else {
        return { open: true, name: node.name, ...node };
      }
    });
  };
  const handleChartChange = (event) => {
    setChartPick(event.target.value)
    changeChart(ChartType[event.target.value])
  }

  const chartSelect = [
    {
      option: 'sankey_node',
      label: 'Sankey Plot'
    },
    {
      option: 'zoom_sunburst',
      label: 'Sunburst with Outcome Response'
    },
    {
      option: 'scatter_plot',
      label: 'Scatter Plot'
    },
    {
      option: 'overall_survival',
      label: 'Overall Survival Rate'
    },
    {
      option: 'progression_free_survival',
      label: 'Progression Free Survival'
    },
    {
      option: 'correlation_heatmap',
      label: 'Correlation Heatmap'
    },
    {
      option: 'geolocation',
      label: 'Geolocation'
    }
  ]

  const loadingBox = (
    <Box
      className={classes.loadingBox}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );

  return (
    <Box className={classes.fullHeight}>
      <Box className={classes.heightAuto}>
        <Paper className={classes.paper}>
          <Help content={<ChartLayoutHelp />} />
          <Grid container spacing={2} sx={{display: "flex", justifyContent: "space-between"}}>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel variant="standard" htmlFor="cohorts-select">Select Cohorts</InputLabel>
                <Select
                  labelId="cohorts-select"
                  id="cohorts-select"
                  inputProps={{
                    name: "Select Cohorts",
                    id: "cohorts-select"
                  }}
                  multiple
                  value={cohorts}
                  onChange={handleChangeMultiple}
                  input={<Input />}
                  renderValue={(selected) => {
                    const selectTitles = [];
                    // console.log(selected);
                    selected.map((s) => {
                      if (s.selected) {
                        selectTitles.push(s.name);
                      }
                      return null;
                    });
                    return selectTitles.join(", ");
                  }}
                >
                  {cohorts.map((c) => (
                    <MenuItem key={c.uid} value={c} >
                      <Checkbox
                        size="small"
                        checked={getMultipleCheck(c, cohorts)}
                      />
                      <Typography sx={{ fontWeight: getMultipleCheck(c, cohorts) ? 'bold' : 'regular'}}>{c.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel variant="standard" htmlFor="charts-select">Select Chart</InputLabel>
                <Select
                  labelId="charts-select"
                  id="charts-select"
                  inputProps={{
                    name: "Select Chart",
                    id: "charts-select"
                  }}
                  value={chartPick}
                  input={<Input />}
                  onChange={handleChartChange}
                >
                  {chartSelect.map((c, key) => (
                    <MenuItem key={key} value={c.option} >{c.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box className={classes.outputFullHeight}>
        <Paper className={clsx(classes.paper, classes.paperFullHeight)}>
          <div className={classes.charts}>
            {chart === ChartType.sunburst ? (
              !sunburstData.loading ? (
                <SunburstChart data={sunburstData.data} />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.zoom_sunburst ? (
              !zoomSunburstData.loading ? (
                <SplitSunburst
                  data={zoomSunburstData.data}
                  popupCharts={popupCharts}
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.overall_survival ? (
              !survivalData.loading ? (
                <SurvivalChart
                  data={survivalData.data}
                  xLabel="Months"
                  yLabel="Percent"
                  title="Overall Survival for Patients in the Selected Cohorts"
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.progression_free_survival ? (
              !progressionFreeSurvivalData.loading ? (
                <SurvivalChart
                  data={progressionFreeSurvivalData.data}
                  xLabel="Months"
                  yLabel="Percent"
                  title="Progression Free Survival for Patients in the Selected Cohorts"
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.sankey ? (
              !sankeyData.loading ? (
                <SankeyChart
                  data={sankeyData.data}
                  togglePopup={handlePopupCharts}
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.scatter_plot ? (
              !scatterPlotData.loading ? (
                <ScatterPlotMatrix
                  data={scatterPlotData.data}
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.correlation_heatmap ? (
              !correlationHeatmapData.loading ? (
                <CorrelationHeatmap
                  data={correlationHeatmapData.data}
                  cohorts={cohorts.filter(c => c.selected)}
                />
              ) : (
                loadingBox
              )
            ) : null}
            {chart === ChartType.geolocation ? (
              !geomapData.loading ? (
                <GeoMap data={geomapData.data} />
              ) : (
                loadingBox
              )
            ) : null}
          </div>
          <PopupChart
            open={popupCharts2.open}
            node={popupCharts2}
            toggle={handlePopupCharts}
            data={popupCharts.popupData}
          />
          <Help
            content={
              chart === ChartType.sankey ? (
                <SankeyHelp />
              ) : chart === ChartType.overall_survival ? (
                <OverallSurvivalHelp />
              ) : chart === ChartType.progression_free_survival ? (
                <ProgressionFreeSurvivalHelp />
              ) : chart === ChartType.zoom_sunburst ? (
                <ZoomSunburstHelp />
              ) : (
                <SampleHelp />
              )
            }
          />
        </Paper>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  ...state.charts,
  ...state.cohorts,
});

const mapDispatch = {
  getVariableList,
  changeChart,
  getCohortsList,
  removeVariable,
  changeFilter,
  clearChartData,
  cohortAdd,
  saveCohortChanges,
  toggleSelected,
};

export default connect(mapStateToProps, mapDispatch)(ChartsLayout);
