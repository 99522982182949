import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { sankey as d3Sankey } from "d3-sankey";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import { Grid, Slider, Typography } from "@mui/material";
import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  slider: {
    paddingRight: 15,
  },
  nodeLabel: {
    font: "6px sans-serif",
  },
}));

// sort array ascending
const asc = (arr) => arr.sort((a, b) => a - b);

const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

function SankeyChart({ data, togglePopup, title }) {
  const ref = useRef();
  const theme = useTheme();
  const classes = useStyles();
  const refDiv = useRef();
  const width = 800;
  const height = (9 / 16) * width;

  const [persons, setPersons] = React.useState(null);

  //console.log(data);
  // const [width, setWidth] = useState(800);
  // const [height, setHeight] = useState((9 / 16) * 800);

  // useEffect(() => {
  //   function handleResize() {
  //     console.log("resize");

  //     if (refDiv && typeof refDiv !== "undefined") {
  //       console.log(refDiv.clientWidth, refDiv.clientHeight);
  //       setHeight(refDiv.clientHeight);
  //       setWidth(refDiv.clientWidth);
  //     }
  //   }
  //   window.addEventListener("resize", handleResize);
  // }, []);

  let labelText = "Group";
  if (togglePopup) {
    labelText = "Line";
  }

  //this state holds the current number of lines of therapy to show
  const [linesToShow, setLinesToShow] = React.useState(5);

  //this state holds the maximum number of lines of therapy in data
  const [maxNumTherapy, setMaxNumTherapy] = React.useState(5);

  //this state holds the data we are to graph
  const [sankeyData, setSankeyData] = React.useState(null);

  // console.log(data);
  useEffect(() => {
    let numLines = 0;
    let x = _.cloneDeep(data.nodes);
    //remove the nodes that are less than lines to show.
    //console.log(linesToShow);
    for (let i = 0; i < x.length; i++) {
      const [, y] = _.split(x[i].name, "_");
      const n = parseInt(y);
      if (numLines < n) {
        numLines = n;
      }
    }
    if(numLines) setMaxNumTherapy(numLines);

    let ids = [];
    for (let i = 0; i < x.length; i++) {
      // console.log(x[i]);

      ids = [...ids, ...x[i].data.map((c) => c.ids)];
    }
    //console.log(ids.length());
    const person_ids = [...new Set(ids)];
    setPersons(person_ids.length);
    //console.log(person_ids.length);
  }, [data]);

  useEffect(() => {
    function replaceNode(datax, oldNode, newNode) {
      // see if the newNode exists.
      // console.log(datax, oldNode, newNode);

      //do a simple cut and replace.
      datax.links.map((x) => {
        if (x.source === oldNode) {
          x.source = newNode;
        }
        if (x.target === oldNode) {
          x.target = newNode;
        }
        return x
      });

      datax.nodes.map((x) => {
        if (x.name === oldNode) {
          x.name = newNode;
        }
        return x
      });

      //combine the nodes which have the same name
      let done = false;
      while (!done) {
        //loop through until we have no replacements
        done = true;
        let index = datax.nodes.findIndex((x) => x.name === newNode);
        if (index >= 0) {
          for (let i = index + 1; i < datax.nodes.length; i++) {
            if (datax.nodes[i].name === newNode && i !== index) {
              //combine the nodes
              datax.nodes[index].data = [
                ...datax.nodes[index].data,
                ...datax.nodes[i].data,
              ];
              //remove the second node
              datax.nodes.splice(i, 1);
              done = false;
            }
          }
        }
      }

      //combine the links which have the same source and target
      done = false;
      while (!done) {
        //loop through until we have no replacements
        done = true;
        for (let j = 0; j < datax.links.length; j++) {
          const s = datax.links[j].source;
          const t = datax.links[j].target;
          for (let i = j + 1; i < datax.links.length; i++) {
            if (
              datax.links[i].source === s &&
              datax.links[i].target === t &&
              i !== j
            ) {
              datax.links[j].value += datax.links[i].value;
              //remove old link
              datax.links.splice(i, 1);
              done = false;
            }
          }
        }
      }

      return datax;
    }

    //clone the data
    //console.log(data);
    let x = _.cloneDeep(data);

    //remove the nodes that are less than lines to show.
    //console.log(linesToShow);
    x.nodes = x.nodes.filter((x) => {
      const [, y] = _.split(x.name, "_");
      const n = parseInt(y);
      //console.log(n);
      return n <= linesToShow;
    });

    x.links = x.links.filter((x) => {
      //console.log(x);
      const [, y] = _.split(x.source, "_");
      const [, y2] = _.split(x.target, "_");
      const n = parseInt(y);
      const n2 = parseInt(y2);
      return n <= linesToShow && n2 <= linesToShow;
    });

    if (1) {
      //filter out the lines which have more 10 nodes.
      for (let i = 1; i < linesToShow + 1; i++) {
        // console.log("testing " + i);
        let nodes = x.nodes.filter((a) => {
          const [, y] = _.split(a.name, "_");
          return i === parseInt(y);
        });
        // console.log(nodes);
        while (nodes.length > 11) {
          //sort nodes from lowest number of people to highest
          let s = nodes.sort((a, b) =>
            a.data.length > b.data.length
              ? 1
              : a.data.length < b.data.length
              ? -1
              : 0
          );
          let index = 0;
          let [name, y] = _.split(s[index].name, "_");
          while (name === "Other") {
            index++;
            [name, y] = _.split(s[index].name, "_");
          }
          x = replaceNode(
            _.cloneDeep(x),
            s[index].name,
            "Other_" + y.toString()
          );
          nodes = x.nodes.filter((x) => {
            const [, y] = _.split(x.name, "_");
            return i === parseInt(y);
          });
        }
      }
    }

    // const nodes = x.nodes.filter((x) => {
    //   const [, y] = _.split(x.name, "_");
    //   return 2 === parseInt(y);
    // });

    setSankeyData(_.cloneDeep(x));
  }, [data, linesToShow]);

  useEffect(() => {
    const draw = async () => {
      const margin = { left: 10, right: 20, top: 10, bottom: 30 };

      const svg = d3
        .select(ref.current)
        .style("width", "100%")
        .attr("viewBox", `0 0 ${width} ${height + 30}`);
      svg.selectAll("*").remove();

      const sankey = d3Sankey()
        .nodeId((d) => d.name)
        .nodeWidth(10)
        .nodePadding(5)
        .size([width, height]);

      const graph = sankey(sankeyData);

      let layerData = [];
      let lines = 0;
      // console.log(linesToShow);
      // console.log(graph);
      const graphLayer = _.orderBy(graph.nodes, (t) => t.layer);
      for (let i = 0; i < graphLayer.length; i++) {
        // console.log(graphLayer[i]);
        if (graphLayer[i].layer === lines) {
          lines++;

          //add offset on first label so in popup first
          // letter is not cut off
          if (lines === 1 && !togglePopup) {
            layerData.push({ lines, x: graphLayer[i].x0 + 20 });
          } else {
            layerData.push({ lines, x: graphLayer[i].x0 });
          }
        }
      }
      // console.log(layerData);

      const defs = svg.append("defs");
      const color = d3
        .scaleOrdinal()
        .domain(sankeyData.nodes)
        .range(theme.charts);

      const sankeyLinkPath = (link) => {
        const offset = 0.5;
        let sx = link.source.x1;
        let tx = link.target.x0 + 1;
        let sy0 = link.y0 - link.width / 2;
        let sy1 = link.y0 + link.width / 2;
        let ty0 = link.y1 - link.width / 2;
        let ty1 = link.y1 + link.width / 2;

        let halfx = (tx - sx) / 2;

        let path = d3.path();
        path.moveTo(sx, sy0);

        let cpx1 = sx + halfx;
        let cpy1 = sy0 + offset;
        let cpx2 = sx + halfx;
        let cpy2 = ty0 - offset;
        path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, tx, ty0);
        path.lineTo(tx, ty1);

        cpx1 = sx + halfx;
        cpy1 = ty1 - offset;
        cpx2 = sx + halfx;
        cpy2 = sy1 + offset;
        path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, sx, sy1);
        path.lineTo(sx, sy0);
        return path.toString();
      };

      const link = svg
        .append("g")
        .attr("fill-opacity", 0.2)
        .selectAll("path")
        .data(graph.links)
        .join("path")
        .attr("d", (d) => sankeyLinkPath(d))
        .sort((a, b) => b.dy - a.dy)
        .on("mouseover", function () {
          d3.select(this).style("fill-opacity", 0.5);
        });

      svg
        .append("g")
        .selectAll(".label")
        .data(layerData)
        .enter()
        .append("g")
        .attr("class", "label")
        .append("text")
        .style("font", "10px sans-serif")
        .style("opacity", 1)
        .attr("text-anchor", "top")
        .attr("alignment-baseline", "middle")
        .attr("x", (d) => {
          let x = d.x + 30;
          return x > width - 10 ? width - 10 : x;
        })
        .attr("y", 5)
        .attr("dy", ".35em")
        .attr("text-anchor", "end")
        .attr("transform", null)
        .text((d) => labelText + ` ${d.lines}`);

      const node = svg
        .append("g")
        .selectAll(".node")
        .data(graph.nodes)
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", (d) => `translate(${d.x0},${d.y0 + 15})`)
        .on("click", (event, d) => {
          if (d.name.includes("No Data")) {
            return false;
          }
          return togglePopup ? togglePopup(d) : false;
        });

      node
        .append("rect")
        .style("shape-rendering", "crispEdges")
        .style("cursor", (d) => (togglePopup ? "pointer" : "default"))
        .attr("height", (d) => d.y1 - d.y0)
        .attr("width", sankey.nodeWidth())
        .attr("fill", (d, i) => color(i))
        .attr("opacity", 0.8);

      node
        .append("text")
        .style("font", "8px sans-serif")
        .style("-webkit-user-select", "none")
        .style("-ms-user-select", "none")
        .style("user-select", "none")
        .style("pointer-events", "none")
        .attr("x", -6)
        .attr("y", (d) => (d.y1 - d.y0) / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "end")
        .attr("transform", null)
        .text((d) => {
          let [x] = _.split(d.name, "_");
          return `${x.split("[")[0]} (${d.value})`;
        })
        .filter((d) => d.x1 < width / 2)
        .attr("x", 6 + sankey.nodeWidth())
        .attr("text-anchor", "start");

      // add mouse over to node
      const nodeMouseOverBox = svg
        .append("g")
        .attr("id", "nodeBox")
        .append("rect")
        .attr("width", 1)
        .attr("height", 1)
        .attr("rx", 6)
        .attr("ry", 6)
        .attr("stroke-width", 1)
        .attr("stroke", "gray")
        .style("fill", "white")
        .style("pointer-events", "none")
        .style("opacity", 0);

      const focusText = svg
        .append("g")
        .attr("id", "focusText")
        .append("text")
        .style("font", "10px sans-serif")
        .style("opacity", 0)
        .attr("text-anchor", "top")
        .attr("alignment-baseline", "middle");

      link.style("fill", (d, i) => {
        const gradientID = `gradient${i}`;
        const startColor = color(d.source.index);
        const stopColor = color(d.target.index);
        const linearGradient = defs
          .append("linearGradient")
          .attr("id", gradientID);
        linearGradient
          .selectAll("stop")
          .data([
            { offset: "10%", color: startColor },
            { offset: "90%", color: stopColor },
          ])
          .enter()
          .append("stop")
          .attr("offset", (d) => d.offset)
          .attr("stop-color", (d) => d.color);

        function mousemove(d, m) {
          const tipPos = () => {
            return m[0] > width - 80 ? width - 80 : m[0] + margin.left;
          };
          let str = "Patients:" + d.value;

          focusText
            .style("opacity", 1)
            .html(`<tspan dy="0" x="${tipPos() + 5}">${str}</tspan>`)
            .attr("x", tipPos())
            .attr("y", m[1] + margin.top);
          var bbox = focusText.node().getBBox();

          // console.log(bbox, tipPos());
          nodeMouseOverBox
            .attr("width", bbox.width + 10)
            .attr("height", bbox.height + 10)
            .attr("x", tipPos())
            .attr("y", m[1] - 15 + margin.top)
            .style("opacity", 1);
        }

        function mouseout() {
          d3.select(this).style("fill-opacity", 0.2); //unhighlight link
          focusText.style("opacity", 0); //remove the pop-up text
          nodeMouseOverBox.style("opacity", 0); //remove the pop-up tex
        }

        link.on("mousemove", function (event, d) {
          let m = d3.pointer(event);
          mousemove(d, m);
        });
        link.on("mouseout", mouseout);
        link.attr("transform", (d) => `translate(0,15)`);

        const nodeMouseOver = svg
          .append("g")
          .attr("id", "focusText")
          .append("text")
          .style("font", "10px sans-serif")
          .style("opacity", 0)
          .attr("text-anchor", "top")
          .attr("alignment-baseline", "middle");

        function nodeMouseMove(d, m) {
          const tipPos = () => {
            const x = m[0] > width - 80 ? width - 80 : m[0] + margin.left//m[0] > width - 120 ? width - 120 : m[0] + margin.left;
            // console.log(`x: ${x}`)
            return x
          };
          const tipPosY = () => {
            const y = m[1] - 15 + margin.top //m[1] > height - 40 ? height - 40 : m[1] + margin.top;
            // console.log(`y: ${y}`)
            return y
          };

          let days = d.data.map((x) => x.days);

          //console.log(asc(days));
          const q5 = quantile(days, 0.05);
          const q50 = quantile(days, 0.5);
          const q95 = quantile(days, 0.95);
          //console.log(days, q5);
          // let avg = _.meanBy(d.data, (x) => {
          //   //console.log(x);
          //   return x.days;
          // });
          // console.log(d);
          let str = "Patients: " + days.length;
          let strDays = `<tspan dy="1.2em" x="${tipPos() + 5}">${
            "Mid. Duration: " + _.round(q50) + " Days"
          }</tspan>
              <tspan dy="1.2em" x="${tipPos() + 5}">${
            "Low Duration: " + _.round(q5) + " Days"
          }</tspan>
              <tspan dy="1.2em" x="${tipPos() + 5}">${
            "High Duration: " + _.round(q95) + " Days"
          }</tspan>`;
          if (d.name.includes("SCT")) {
            strDays = `<tspan dy="1.2em" x="${tipPos() + 5}">
                ${"Duration: 1 Day"}
              </tspan>`;
          }

          if (d.name.includes("No Data")) {
            strDays = "";
          }
          nodeMouseOver
            .style("opacity", 1)
            .html(
              `<tspan dy="0" x="${tipPos() + 5}"> ${str} </tspan>` + strDays
            )
            .attr("x", tipPos())
            .attr("y", tipPosY());
          var bbox = nodeMouseOver.node().getBBox();

          //console.log(bbox, tipPos());
          nodeMouseOverBox
            .attr("width", bbox.width + 10)
            .attr("height", bbox.height + 10)
            .attr("x", tipPos())
            .attr("y", tipPosY() - 15)
            .style("opacity", 1);
        }

        node
          .on("mousemove", function (event, d) {
            let m = d3.pointer(event, event.target.parentNode.parentNode);
            // console.log(m)
            nodeMouseMove(d, m);
          })
          .on("mouseout", function () {
            nodeMouseOverBox.style("opacity", 0);
            nodeMouseOver.style("opacity", 0); //remove the pop-up text
          });
        //node mouse over done...
        return `url(#${gradientID})`;
      });
    };
    if (sankeyData) {
      if (sankeyData.nodes.length > 0) {
        // console.log(sankeyData);
        draw();
      }
    }
  }, [sankeyData, theme.charts, width, height, labelText, togglePopup]);

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <div
      ref={refDiv}
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography align="center" variant="h6" gutterBottom>
        {title
          ? title
          : "Baseline Treatment by Line of Therapy for the Selected Cohorts (" +
            persons +
            ")"}
      </Typography>

      <svg className="sankey" ref={ref} />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography align="right">Number Lines of Therapy</Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.slider}>
            <Slider
              defaultValue={5}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-always"
              valueLabelDisplay="on"
              step={1}
              marks
              min={2}
              max={maxNumTherapy}
              value={linesToShow}
              onChange={(e, val) => {
                setLinesToShow(val);
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SankeyChart;
